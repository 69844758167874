import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import api from "../lib/api";
import ButtonValid from "../utils/ButtonValid";
import { ChevronLeft } from "lucide-react";
import { isAdmin } from "../lib/auth";
import ButtonRemove from "../utils/ButtonTransparent";

const PainelAdmUser = () => {
  const [userCh, setUserCh] = useState<any[]>([]);
  const [challenges, setChallanges] = useState<any[]>([]);
  const [days, setDays] = useState<any[]>([]);
  const [selectedDay, setSelectedDay] = useState("1");
  const [doneChallenges, setDoneChallenges] = useState<any[]>([]);
  const [validatedChallenges, setValidatedChallenges] = useState<any[]>([]);
  const [admin, setAdmin] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const infoUser = useLocation(); // Declare the infoUser variable

  isAdmin().then((response) => {
    setAdmin(response);
  });

  const deleteUser = async () => {
    // Chamar api para deletar usuário
    try{
      const response = await api.post(`/deleteUser/${infoUser.state.info.id}`,{
        headers: {
          "Content-Type": "application/json", // Define o cabeçalho Content-Type como application/json
        },
      });
      if(response.status === 200){
        alert("Usuário deletado com sucesso");
        window.location.href = "/participantes";
      }
    }catch(error){
      console.error(error);
    }
    console.log("deletar user");
  };

  useEffect(() => {
    // While loading, load the state from the localStorage.
    const storedDay = window.localStorage.getItem("Dia");

    if (storedDay !== null) {
      setSelectedDay(storedDay);
    }
  }, []);

  useEffect(() => {
    async function getInfos() {
      try {
        const response = await api.get(
          `/userChallenges/${infoUser.state.info.id}`
        );
        setUserCh(response.data);
        setDoneChallenges(response.data.length);
        setValidatedChallenges(
          response.data.filter((item: any) => item.isValidated === true).length
        );
      } catch (error) {
        console.error(error);
      }
    }
    getInfos();
  }, []);

  useEffect(() => {
    async function getInfos() {
      try {
        const response = await api.get("/challenges");
        setChallanges(response.data);
      } catch (error) {
        console.error(error);
      }
    }
    getInfos();
  }, []);

  useEffect(() => {
    async function getDays() {
      try {
        const response = await api.get("/days");
        setDays(response.data);
      } catch (error) {
        console.error(error);
      }
    }
    getDays();
  }, []);

  async function validateChallenge(
    userId: Number,
    challengeId: Number,
    dayId: Number
  ) {
    const data = {
      userId,
      challengeId,
      dayId,
    };
    try {
      const response = await api.post("/userChallenges/validate", data, {
        headers: {
          "Content-Type": "application/json", // Define o cabeçalho Content-Type como application/json
        },
      });

      window.localStorage.setItem("Dia", dayId.toString());
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      {infoUser ? (
        <>
          <div>
            <div>
              <ChevronLeft
                className="text-white absolute m-24 h-16 w-16 cursor-pointer hover:text-orange-500"
                onClick={() => window.history.back()}
              />
            </div>
            <div className="flex justify-center">
              <img
                src={infoUser.state.info.avatarURL}
                alt="Foto do Usuário"
                className="rounded-3xl w-40 mb-8 mt-20"
              />
              <div className="ml-5">
                <h3 className="text-3xl font-bold mb-5 mt-24 text-white">
                  {infoUser.state.info.name}
                </h3>
                <p className="text-xl mb-5">
                  Desafios feitos: {doneChallenges}
                </p>
                <p className="text-xl">
                  Desafios validados: {validatedChallenges}
                </p>
                {admin && !infoUser.state.info.isAdmin && (
                  <div className="mt-5">
                    {!confirm ? 
                    <button onClick={() => setConfirm(true)}>
                      <p className="text-xl text-orange-400">
                        Excluir participante
                      </p>
                    </button>
                    : <p className="text-orange-400">
                        Tem certeza que deseja excluir
                        <br />  
                        <span className="font-bold text-white">{infoUser.state.info.name}</span>?
                      </p>
                    }
                    {confirm && (
                      <div className="flex row gap-5 mt-5">
                        <ButtonRemove
                          title="Excluir"
                          onClick={() => deleteUser()}
                        />
                        <button onClick={() => setConfirm(false)} className="hover:text-orange-500">
                          Cancelar
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className="mt-8">
              <select
                name="days"
                id="days"
                className="bg-transparent hover:bg-white hover:bg-opacity-10 text-center font-bold text-2xl block mx-auto rounded-lg p-2 text-white"
                onChange={(e) => setSelectedDay(e.target.value)}
              >
                {days.map((item, index) => {
                  return (
                    <>
                      {Number(selectedDay) === item.day ? (
                        <option
                          value={item.id}
                          label={`Dia ${item.day}`}
                          selected
                        >
                          {item.day}
                        </option>
                      ) : (
                        <option value={item.id} label={`Dia ${item.day}`}>
                          {item.day}
                        </option>
                      )}
                    </>
                  );
                })}
              </select>
              <div className="flex items-center justify-center">
                {userCh.map((item, index) => {
                  return (
                    <>
                      {item.dayId === Number(selectedDay) ? (
                        <div className="text-center ml m-8">
                          <h3 className="text-3xl font-bold mb-8 text-white text-center">
                            {item.challengeId === 1
                              ? "Água 2l"
                              : item.challengeId === 2
                              ? "Livro"
                              : item.challengeId === 3
                              ? "Salada"
                              : item.challengeId === 4
                              ? "2 frutas"
                              : item.challengeId === 5
                              ? "Exercício Físico"
                              : item.challengeId === 6
                              ? "Sono"
                              : null}
                          </h3>
                          <img
                            src={item.photoURL}
                            alt="imagem a ser validada"
                            className="mb-8 h-40 w-40"
                          />
                          {item.isValidated ? (
                            <p className="text-green-400">Validado</p>
                          ) : (
                            <ButtonValid
                              onClick={() =>
                                validateChallenge(
                                  item.userId,
                                  item.challengeId,
                                  item.dayId
                                )
                              }
                              title="Validar"
                            />
                          )}
                        </div>
                      ) : null}
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default PainelAdmUser;
