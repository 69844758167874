import React, { useEffect, useState } from "react";
import api from "../lib/api";
import { isAdmin } from "../lib/auth";
import imgdefault from "../../assets/default.jpg";
import Footer from "../utils/Footer";

function Ranking() {
  const [users, setUsers] = useState<any[]>([]);
  const [admin, setAdmin] = useState(false);
  const [edit, setEdit] = useState(false);

  isAdmin().then((response) => {
    setAdmin(response);
  });

  useEffect(() => {
    async function getUsers() {
      try {
        const response = await api.get("/ranking");
        setUsers(response.data);
      } catch (error) {
        console.error(error);
      }
    }

    getUsers();
  }, []);

  const increasePoints = async (userId: string) => {
    try {
      const updatedUsers = users.map((user) => {
        if (user.id === userId) {
          return {
            ...user,
            totalPoints: user.totalPoints + 1,
            hasChanged: true,
          };
        }
        return user;
      });
      setUsers(updatedUsers);
    } catch (error) {
      console.error(error);
    }
  };

  const decreasePoints = async (userId: string) => {
    try {
      const updatedUsers = users.map((user) => {
        if (user.id === userId) {
          return {
            ...user,
            totalPoints: user.totalPoints - 1,
            hasChanged: true,
          };
        }
        return user;
      });
      setUsers(updatedUsers);
    } catch (error) {
      console.error(error);
    }
  };

  const saveEdit = async (users: any[]) => {
  console.log("eu sou um aviso");
    users.forEach(async (element) => {
      console.log("Elemento:", element);
      if (element.hasChanged) {
        // Chamar API para salvar os pontos do usuário
        const response = await api.post("/ranking", element, {
          headers: {
            "Content-Type": "application/json", // Define o cabeçalho Content-Type como application/json
          },
        });

        console.log(response.data);

        // console.error("Erro ao salvar os pontos");
        // return
      }
    });

    setEdit(false);
  };

  return (
    <div>
      <div className="text-center mb-10">
        <div className="flex justify-center items-center flex-col">
          <h1 className="text-center text-orange-400 font-bold mt-10 lg:mt-28 text-2xl lg:text-5xl">
            Ranking
          </h1>
          {admin && (
            <div className="flex justify-center items-center gap-10 mt-1 lg:mt-18">
              {!edit && (
                <button type="button" onClick={() => setEdit(true)}>
                  Editar
                </button>
              )}
              {!!edit && (
                <button type="button" onClick={() => saveEdit(users)}>
                  Salvar
                </button>
              )}
            </div>
          )}
        </div>
      </div>
      <div
        className="ranking"
        style={{
          maxHeight: "500px",
          paddingRight: "15px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        {users.map((item, index) => (
          <div className="mb-16" key={index}>
            {item.avatarURL ? (
              <img
                src={item.avatarURL}
                alt="Foto do usuário"
                className="rounded-3xl w-32 mb-8 m-auto"
              />
            ) : (
              <img
                src={imgdefault}
                alt="Foto do usuário"
                className="rounded-3xl w-32 mb-8 m-auto"
              />
            )}
            <h1 className="text-3xl font-bold mb-4 text-white">
              {index + 1}º - {item.name}
            </h1>
            <p>Pontos: {item.totalPoints}</p>
            {edit && (
              <div className="flex justify-center">
                <button
                  className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
                  onClick={() => increasePoints(item.id)}
                >
                  +
                </button>
                <button
                  className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded ml-2"
                  onClick={() => decreasePoints(item.id)}
                >
                  -
                </button>
              </div>
            )}
          </div>
        ))}
        <Footer></Footer>
      </div>
    </div>
  );
}

export default Ranking;
