interface ButtonProps {
    disabled?: boolean;
    onClick?: () => void;
    title: string;
    Icon?: any;
}

export default function ButtonRemove({ 
    disabled,
    onClick,
    title,
    Icon
}: ButtonProps) {
  return (
    <button 
        disabled={disabled}
        onClick={onClick}
        className="bg-transparent text-white border-[1px] p-2 rounded-xl w-[130px]
        hover:text-white hover:bg-red-500 border-white hover:border-red-500 gap-2
        transition-all duration-300 ease-in-out disabled:cursor-not-allowed flex justify-center"
        >
        {title}
        {Icon && <Icon/>}
    </button>
  );
}