import { useEffect, useState } from "react";
import api from "../lib/api";
import ButtonChallenges from "../utils/ButtonChallenges";
import ButtonRemove from "../utils/ButtonRemove";
import Footer from "../utils/Footer";

function Challenges() {
  const [days, setDays] = useState<any[]>([]);

  useEffect(() => {
    async function getDays() {
      const response = await api.get("/days");
      setDays(response.data);
    }

    getDays();
  }, []);

  async function activeDay(day: Number) {
    for (let i = 0; i < days.length; i++) {
      if (days[i].isActive === true) {
        alert("Um dia já está ativo, desative-o para ativar outro");
        return;
      }
    }

    const response = await api.put("/days", {
      dayId: day,
      isActive: true,
    });

    if (response.status === 200) {
      alert("Dia ativado com sucesso!");
      window.location.reload();
    } else {
      console.error("Erro no servidor:", response.status);
    }
  }

  async function disableDay(day: Number) {
    const response = await api.put("/days", {
      dayId: day,
      isActive: false,
    });

    if (response.status === 200) {
      alert("Dia desativado com sucesso!");
      window.location.reload();
    } else {
      console.error("Erro no servidor:", response.status);
    }
  }

  return (
    <div className="text-center px-5 pb-10">
      <div className="flex justify-center items-center">
        <h1 className="text-center text-orange-400 font-bold mt-8 lg:mt-28 text-2xl lg:text-5xl mb-4 lg:mb-16">
          Desafios
        </h1>
      </div>
      <div>
        <div className="flex justify-center items-center mt-16 flex-wrap flex justify-center items-center flex-wrap gap-10">
          {days.map((item, index) => (
            <div>
              <div key={index}>
                <p className="text-3xl font-bold mb-8">Dia {item.day}</p>
                {item.isActive ? (
                  <ButtonRemove
                    onClick={() => disableDay(item.day)}
                    title="Desativar"
                  />
                ) : (
                  <ButtonChallenges
                    onClick={() => activeDay(item.day)}
                    title="Ativar"
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default Challenges;
