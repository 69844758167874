export const NavbarData = [
  {
    title: "Desafios do Dia",
    path: "/",
    isAdminPage: false,
  },
  {
    title: "Cadastro",
    path: "/cadastro",
    isAdminPage: true,
  },
  {
    title: "Participantes",
    path: "/participantes",
    isAdminPage: true,
  },
  {
    title: "Desafios",
    path: "/desafios",
    isAdminPage: true,
  },
  {
    title: "Ranking",
    path: "/ranking",
    isAdminPage: false,
  },
];
