interface ButtonProps {
    disabled?: boolean;
    onClick?: () => void;
    title: string;
}

export default function ButtonRemove({ 
    disabled,
    onClick,
    title 
}: ButtonProps) {
  return (
    <button 
        disabled={disabled}
        onClick={onClick}
        className="bg-transparent text-red-500 border-[1px] p-2 rounded-xl w-[130px]
        hover:text-white hover:bg-red-500 border-red-500 
        transition-all duration-300 ease-in-out disabled:cursor-not-allowed"
        >
        {title}
    </button>
  );
}