import React from "react";
import img from "../../assets/logo_linked_branca.png";

const Footer = () => {
  return (
    <>
      <footer className="p-4 flex items-center mt-10 ml-5 gap-5">
        <h3 className="text-xl text-orange-400">Desenvolvido por </h3>
        <a
          href="https://www.linkedej.com.br"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={img} className="w-40" alt="Logo Linked EJ" />
        </a>
      </footer>
    </>
  );
};

export default Footer;
