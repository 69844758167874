import Cookies from "js-cookie";
import img from "../../assets/img1.jpeg";
import api from "../lib/api";
import ButtonOrange from "../utils/ButtonOrange";
import { useEffect, useState } from "react";
import Footer from "../utils/Footer";

function Login() {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setTimeout(() => {
      setError(null);
    }, 6000);
  }, [error]);

  const handleLoginChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLogin(e.target.value);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const userData = {
      login,
      password,
    };

    try {
      const response = await api.post("/login", userData, {
        headers: {
          "Content-Type": "application/json", // Define o cabeçalho Content-Type como application/json
        },
      });

      // Verifique a resposta do servidor, redirecione se necessário
      if (response.status === 200) {
        const data = response.data;
        Cookies.set("token", data, { expires: 3 });
        window.location.href = "/"; // Redireciona para a página inicial
      } else {
        console.error("Erro no servidor:", response.status);
      }
    } catch (error) {
      setError("Email ou senha incorretos");
    }
  }

  return (
    <div className="text-white flex flex-col lg:flex-row">
      <div>
        {error && (
          <div
            id="error"
            className="bg-red-500 mt-8 transition-opacity duration-300 text-white p-2 text-center absolute left-[50%] translate-x-[-50%] z-10 rounded"
          >
            <span className="m-16 font-bold">{error}</span>
          </div>
        )}
      </div>
      <div className="lg:w-1/2 relative h-screen overflow-hidden">
        <img
          src={img}
          alt="Desafio Ambiente Consistente"
          className="w-full h-full object-cover opacity-30 rounded-xl"
        ></img>
        <div className="absolute text-center flex top-[50%] w-full">
          <div className="block mx-auto">
            <p className="font-bold lg:text-5xl md:text-4xl text-3xl">
              Desafio Ambiente Consistente
            </p>
          </div>
        </div>
      </div>
      <div className="lg:w-1/2 flex items-center justify-center">
        <div className="w-full lg:w-3/4 px-4 lg:px-0 mb-6 lg:mb-12 mt-6 lg:mt-12">
          <h2 className="text-3xl font-bold mb-6 lg:mb-12 lg:text-4xl md:text-3xl text-2xl">
            Login
          </h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-4 lg:mb-8">
              <label htmlFor="login">Login</label>
              <input
                className="mt-2 lg:mt-4 w-full border-b border-gray-600 bg-transparent px-2 py-1 focus:border-blue-500 focus:outline-none"
                type="text"
                name="login"
                id="login"
                value={login}
                onChange={handleLoginChange}
                placeholder="Digite seu login"
              />
            </div>
            <div className="mb-4 lg:mb-8">
              <label htmlFor="password">Senha</label>
              <br />
              <input
                className="mt-2 lg:mt-4 w-full border-b bg-transparent border-gray-600 px-2 py-1 focus:border-blue-500 focus:outline-none"
                type="password"
                name="password"
                id="password"
                value={password}
                onChange={handlePasswordChange}
                placeholder="Digite sua senha"
              />
            </div>
            <ButtonOrange title="Entrar" />
          </form>
          <div className="flex justify-center items-center">
            <Footer></Footer>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
