import { useState } from "react";
import ButtonOrange from "../utils/ButtonOrange";
import { getAdmin } from "../lib/auth";
import api from "../lib/api";
import Footer from "../utils/Footer";

const Register = () => {
  const [name, setName] = useState("");
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");

  getAdmin(); //Verifica se o usuário é admin

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleLoginChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLogin(e.target.value);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const userData = {
      name,
      login,
      password,
    };

    try {
      const response = await api.post("/register", userData, {
        headers: {
          "Content-Type": "application/json", // Define o cabeçalho Content-Type como application/json
        },
      });

      // Verifique a resposta do servidor, redirecione se necessário
      if (response.status === 200) {
        alert(response.data);
        window.location.reload();
      } else {
        console.error("Erro no servidor:", response.status);
      }
    } catch (error) {
      // Trate erros aqui
      console.error(error);
    }
  };

  return (
    <div>
      <div className="flex justify-center items-center">
        <h1 className="text-center text-orange-400 font-bold mt-8 lg:mt-28 text-2xl lg:text-5xl mb-4 lg:mb-16">
          Cadastrar Participantes
        </h1>
      </div>
      <div className="flex justify-center">
        <div className="w-full max-w-md px-4">
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label htmlFor="name">Nome</label>
              <input
                className="mt-2 lg:mt-4 w-full border-b border-gray-600 bg-transparent px-2 py-1 focus:border-blue-500 focus:outline-none"
                type="text"
                name="name"
                id="name"
                value={name}
                onChange={handleNameChange}
              />
            </div>
            <div>
              <label htmlFor="login">Login</label>
              <input
                className="mt-2 lg:mt-4 w-full border-b border-gray-600 bg-transparent px-2 py-1 focus:border-blue-500 focus:outline-none"
                type="text"
                name="login"
                id="login"
                value={login}
                onChange={handleLoginChange}
              />
            </div>
            <div>
              <label htmlFor="password">Senha</label>
              <input
                className="mt-2 lg:mt-4 w-full border-b border-gray-600 bg-transparent px-2 py-1 focus:border-blue-500 focus:outline-none"
                type="password"
                name="password"
                id="password"
                value={password}
                onChange={handlePasswordChange}
              />
            </div>
            <div>
              <ButtonOrange title="Cadastrar" />
            </div>
          </form>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Register;
