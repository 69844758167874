import { Ban } from 'lucide-react';
import React from 'react';

const Forbidden: React.FC = () => {
    return (
        <div className='text-center h-screen'>
            <div className='absolute z-[-1] top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]'>
                <Ban className='m-auto w-16 h-16 text-red-500'/>
                <h1 className='text-6xl mt-2 mb-4 text-red-500 font-thin'>Error 403: Forbidden</h1>
                <p>Você não possui permissão para acessar essa página</p>
            </div>
        </div>
    );
};

export default Forbidden;