import Cookies from 'js-cookie';
import {jwtDecode} from 'jwt-decode';
import api from './api';

interface User {
  sub: string;
  name: string;
  avatarURL: string;
}

export function getUser(): User {
  const token = Cookies.get('token');

  if (!token) {
    window.location.href = "/login";
    throw new Error("Você precisa estar logado para acessar essa página");
  }

  const user: User = jwtDecode(token);
  return user;
}

export function logout() {
  Cookies.remove('token');
  window.location.href = "/login";
}

export async function getAdmin(): Promise<User> {
  const token = Cookies.get('token');

  if (!token) {
    window.location.href = "/login";
    throw new Error("Você precisa estar logado para acessar essa página");
  }

  const user: User = jwtDecode(token);

  const response = await api.get(`/isAdmin/${user.sub}`);

  if(response.data === false){
    window.location.href = "/forbidden";
    throw new Error("Você não tem permissão para acessar essa página");
  }
  
  return user;
}

export async function isAdmin(): Promise<boolean> {
  const token = Cookies.get('token');

  if (!token) {
    window.location.href = "/login";
    throw new Error("Você precisa estar logado para acessar essa página");
  }

  const user: User = jwtDecode(token);

  const response = await api.get(`/isAdmin/${user.sub}`);

  return response.data;
}