import Loader from "./Loader/Loader";

interface ButtonProps {
    disabled?: boolean;
    isLoading?: boolean;
    onClick?: () => void | Promise<void>;
    title: string;
}

export default function ButtonOrange({ 
    disabled,
    onClick,
    isLoading,
    title 
}: ButtonProps) {
  return (

    <button 
        disabled={disabled}
        onClick={onClick}
        className="bg-orange-500 text-white p-2 rounded-xl w-full
        hover:text-orange-500 hover:bg-white transition-all duration-300 ease-in-out
        disabled:bg-orange-700 disabled:cursor-not-allowed" 
        >
        {isLoading? 
            <Loader />
          : <span>{title}</span>
        }
    </button>
    
  );
}