interface ButtonProps {
    disabled?: boolean;
    onClick?: () => void | Promise<void>;
    title: string;
}

export default function ButtonChallenges({ 
    disabled,
    onClick,
    title 
}: ButtonProps) {
  return (
    <button 
        disabled={disabled}
        onClick={onClick}
        className="bg-transparent text-white border-[1px] p-2 rounded-xl w-[130px]
        hover:text-black hover:bg-white transition-all duration-300 ease-in-out
        focus:text-white, focus:bg-orange-500 disabled:cursor-not-allowed"
        >
        {title}
    </button>
  );
}